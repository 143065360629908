import React, { useEffect, useState } from 'react'
import  play_store_image  from '../../assets/images/play_store.png';
import  app_store_image  from '../../assets/images/app-store.png';
import  pro_logo_image  from '../../assets/images/pro-logo.png';
import  ss_1_image  from '../../assets/images/ss_1.png';
import  image_2a  from '../../assets/images/2a.png';
import  features_2_image  from '../../assets/images/features_2.png';
import  vaishnavi_image  from '../../assets/images/vaishnavi.png';
import  ram_image  from '../../assets/images/ram.png';
import  aishwariya_image  from '../../assets/images/aishwariya.png';
import  vamshi_image  from '../../assets/images/vamshi.png';
import  desktop_bg_image  from '../../assets/images/desktop-bg.png';
import './BasicWithPage.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import videoPic from '../../assets/images/videos-pic.png';
import FootNav from '../../components/foot-nav/FootNav';
import Navbar from '../../components/top-nav/top-nav';
import charlesNrobertImg from '../../assets/images/vendors/charles-n-robert.png';
import desamOrganicsImg from '../../assets/images/vendors/desam-organics.png';
import divynteaImg from '../../assets/images/vendors/divyntea.png';
import gharSoapsImg from '../../assets/images/vendors/ghar-soaps.png';
import rubabImg from '../../assets/images/vendors/rubab.png';
import saemHospitablityImg from '../../assets/images/vendors/saem-hospitablity.png';
import beansterCompanyImg from '../../assets/images/vendors/the-beanster-company.png';
import chymeraCompanyImg from '../../assets/images/vendors/the-chymera-company.png';
import cozyQuirkyImg from '../../assets/images/vendors/the-cozy-quirky.png';
import herbalZestImg from '../../assets/images/vendors/the-herbal-zest.png';
import kettleManImg from '../../assets/images/vendors/the-kettle-man.png';
import rainStoryImg from '../../assets/images/vendors/rain-story.png';
import teaBroImg from '../../assets/images/vendors/the-tea-bro.png';
import tuckshopIndiaImg from '../../assets/images/vendors/the-tuckshop-india.png';
import unsweetenedBeautyImg from '../../assets/images/vendors/unsweetened-beauty.png';
import wiseLifeImg from '../../assets/images/vendors/wise-life.png';
import wrappedUpLoveImg from '../../assets/images/vendors/wrapped-up-love.png';


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
};

const JEEWITH_VENDORS = [
  { name: 'Charles N Robert', image: charlesNrobertImg },
  { name: 'Desam Organics', image: desamOrganicsImg },
  { name: 'Divyntea', image: divynteaImg },
  { name: 'Ghar Soaps', image: gharSoapsImg },
  { name: 'Rubab', image: rubabImg },
  { name: 'Saem Hospitablity', image: saemHospitablityImg },
  { name: 'The Beanster Company', image: beansterCompanyImg },
  { name: 'The Chymera Company', image: chymeraCompanyImg },
  { name: 'The Cozy Quirky', image: cozyQuirkyImg },
  { name: 'The Herbal Zest', image: herbalZestImg },
  { name: 'The Kettle Man', image: kettleManImg },
  { name: 'The Rain Story', image: rainStoryImg },
  { name: 'The Tea Bro', image: teaBroImg },
  { name: 'The Tuckshop India', image: tuckshopIndiaImg },
  { name: 'Unsweetened Beauty', image: unsweetenedBeautyImg },
  { name: 'Wise Life', image: wiseLifeImg },
  { name: 'Wrapped Up Love', image: wrappedUpLoveImg },
];

const BasicWithPage = () => {
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    setVendors(JEEWITH_VENDORS);
  }, []);

  return (
    <div className="container-fluid minh-100">
           {/* Mobile View */}
           <Navbar />
        <div className="d-lg-none" style={{ background: "#050505" }}>
      <div>
        <div className="row">
          <div className="col-12">
            <div className="overflow-hidden br-3">
              {/* Hero Image */}
              <div className="p-5 mt-5rem bg-img-mb">
                <div className="text-align-center">
                  <div className="row">
                    <div className="col-12 pl-0 pt-7rem">
                      <div>
                        <h1 className="title fs-18 text-white fw-600">
                          Holistic Wellness Starts with Building Healthy Habits
                        </h1>
                        <h2 className="title fs-11 text-white fw-400 mb-3 lh-20 ls--0x5 pt-4">
                          Be Active, Stay Lively and Get Rewarded.
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                {/* Hero images */}
                <div className="row">
                  <div className="col-6">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.happyhealthylife.with&hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={play_store_image} className="shadow br-1 img-height" alt="Play Store" />
                    </a>
                  </div>
                  <div className="col-6 pl-0">
                    <a
                      href="https://apps.apple.com/in/app/jeewith/id1560598766"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={app_store_image} className="shadow br-1 img-height" alt="App Store" />
                    </a>
                  </div>
                </div>
                {/* Introduction to Jeewith */}
                <div className="row pt-35 pl-22">
                  <div className="col-10 pr-0 pl-6 r-2">
                    <span className="fs-12 text-white fw-500 mt-1">
                      Habit Building Made Easy and Rewarding
                    </span>
                  </div>
                  <div className="col-2 p-0 img-pro">
                    <img src={pro_logo_image} className="img-height" alt="Pro Logo" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <img src={ss_1_image} className="ss-1" alt="Screenshot 1" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pl-4 pr-2x5 pt-8 pb-2x5 mb-2">
                    <span className="fs-13 txt-col fw-500">
                      In Jeewith, you can subscribe to wellness activities and build your habits by tracking your routines.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Features */}
        <div className="row pt-8" style={{ background: "#2F2F2F" }}>
          <div className="row pl-2">
            <div className="col-12">
              <span className="fs-18 text-white fw-600">
                Keep a track of your overall wellness
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img src={image_2a} className="img-2a" alt="Feature Image 2a" />
            </div>
          </div>
          <div className="row pt-21 pl-2x5 pr-40">
            <div className="col-12">
              <span className="fs-10 fw-500 txt-col">
                Experience this journey of wellness by taking small steps every day and rewarding yourself for every activity you do.
              </span>
            </div>
          </div>
          <div className="row pt-21 pl-2x5 pr-40">
            <div className="col-12">
              <span className="fs-15 fw-600 text-white">
                We reward you to stay healthy
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-4 pl-5 pr-0 m-0 pt-5">
              <span className="fs-7 fw-500 txt-col">
                Every effort goes with recognition. Update your wellness activities and earn exciting rewards!
              </span>
            </div>
            <div className="col-8 pl-0 m-0 pb-5">
              <div className="col-12">
                <img src={features_2_image} className="img-mb" alt="Features 2" />
              </div>
            </div>
          </div>
          {/* Testimonials and Track Record */}
          <div className="row">
            <div className="col-12 l-1">
              <span className="fs-15 fw-600 text-white">
                Testimonials
              </span>
            </div>
          </div>
          <div className="pt-8 overflow-x">
          <Slider {...settings}>
      <div>
        <div className="d-flex flex-column align-items-center pt-5 pb-5">
          <div className="p-2 m-3" style={{ flex: 1 }}>
            <div className="br-2 overflow-hidden">
              <img src={vaishnavi_image} className="img-fluid w-100 mb-3 br-1" alt="Vaishnavi" />
            </div>
          </div>
          <div className="p-2 m-3" style={{ flex: 1 }}>
            <p className="fs-11 mb-0 font-light-black-c ff-gilroy-medium">
              The one major change I found in myself was that I was looking forward to reaching the goals that I set for myself.
            </p>
            <h3 className="fs-15 mt-4 font-white">- Vaishnavi</h3>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex flex-column align-items-center pt-5 pb-5">
          <div className="p-2 m-3" style={{ flex: 1 }}>
            <div className="br-2 overflow-hidden">
              <img src={ram_image} className="img-fluid w-100 mb-3 br-1" alt="Sai Pavan" />
            </div>
          </div>
          <div className="p-2 m-3" style={{ flex: 1 }}>
            <p className="fs-11 mb-0 font-light-black-c ff-gilroy-medium">
              I’ve started waking up early in the mornings, it helps me stay hydrated by reminding me to drink water regularly, this app helped me become a morning person! I love this app and will continue using it.
            </p>
            <h3 className="fs-15 mt-4 font-white">- Sai Pavan</h3>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex flex-column align-items-center pt-5 pb-5">
          <div className="p-2 m-3" style={{ flex: 1 }}>
            <div className="br-2 overflow-hidden">
              <img src={aishwariya_image} className="img-fluid w-100 mb-3 br-1" alt="Aishwarya" />
            </div>
          </div>
          <div className="p-2 m-3" style={{ flex: 1 }}>
            <p className="fs-11 mb-0 font-light-black-c ff-gilroy-medium">
              I’ve seen some considerable changes in my everyday lifestyle since I started using the app. I developed some new healthy habits like waking up early in the morning to maintain a fitness routine.
            </p>
            <h3 className="fs-15 mt-4 font-white">- Aishwarya</h3>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex flex-column align-items-center pt-5 pb-5">
          <div className="p-2 m-3" style={{ flex: 1 }}>
            <div className="br-2 overflow-hidden">
              <img src={vamshi_image} className="img-fluid w-100 mb-3 br-1" alt="Vamshi" />
            </div>
          </div>
          <div className="p-2 m-3" style={{ flex: 1 }}>
            <p className="fs-11 mb-0 font-light-black-c ff-gilroy-medium">
              The app is very positive, reminding me to keep my body hydrated and also helps me keep up with my physical fitness. I love tracking my wellness activities. This is promoting me to be productive and more energetic.
            </p>
            <h3 className="fs-15 mt-4 font-white">- Vamshi Krishna Naik</h3>
          </div>
        </div>
      </div>
    </Slider>
               {/* Premium */}
               <div id="Get_Premium" className="pt-55 pb-7rem bg-grey-2">
                <div className="container">
                  <div>
                    <p className="font-white fs-18 fw-600 ts-black-3 text-center"><span style={{color: "#1B7BD3"}}>Jeewith Premium</span>
                      &nbsp; goes live on <span className="font-warning">30th April</span> ! Mark your calenders.
                    </p>
                  </div>
                  <img className='img-fluid' src= {videoPic}/>
                  <div class="row pt-2">
                <div class="col-12 pl-4">
                  <div class="pt-3 p-4 fs-12">
                    <ul class="list-unstyled">
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">100+ Healthy Recipies</span>
                      </li>
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">Access to exclusive workout videos</span>
                      </li>
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">50% Higher chances of redeeming wellness rewards</span>
                      </li>
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">Elite access to Jeewith Tools like Diet planner, Workout builder, Analytics, Wearable app.</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="pt-11">
            <div class="container">
              <div class="row">
                <div class="col-12 pl-2 pt-3 pb-3">
                  <h5 class="fs-18 fw-600 font-white">Transform your workforce with <span
                   style={{ color: "#1B7BD3"}}>Jeewith</span>
                  </h5>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-12 pl-4">
                  <div class="pt-3 p-4 fs-12">
                    <ul class="list-unstyled">
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">Run your own challenges.</span>
                      </li>
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">Get access to <span style={{ color: "#1B7BD3"}}>Jeewith Premium</span> at subsidized prizes</span>.
                      </li>
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">Customize your own products for the store.</span>
                      </li>
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">Earn redeemable reward points.</span>
                      </li>
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">Engage and Empower your employees.</span>
                      </li>
                      <li class="txt-col">
                      <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                        <span class="fs-10 fw-400">Compete with co-workers in fun challenges that reward and make employees fitter, together.</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
                </div>
              </div>
              {/* transform workspace */}
              
          </div>
          {/* Footer */}
          <FootNav />
        </div>
      </div>
    </div>

    {/* Desktop View */}
    <Navbar />
    <div className="d-none d-lg-block">
      <div>
        <div className="row">
          <div className="col-md-12 col-12 p-0">
            <div className="scrollspy-example overflow-hidden br-3" data-bs-spy="scroll" data-bs-target="#navbar-example2"
                 data-bs-offset="0" tabIndex="0">
              {/* Hero Image */}
              <div className="p-4 w-100p bg-transparent"
                   style={{ background: `url(${desktop_bg_image})`, backgroundSize: 'cover' }}>
                <div className="text-align-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-2 col-12"></div>
                      <div className="col-md-8 col-12 pt-13rem pb-13rem">
                        <div>
                          <h5 className="fs-30 font-white fw-600 ws-1 ls-5 ts-black-5">Holistic Wellness Starts with
                            Building Healthy Habits</h5>
                          <h6 className="fs-13 font-light-black-c text-white fw-400 mt-4 ff-gilroy-medium">Be Active, Stay
                            Lively and Get Rewarded.</h6>
                        </div>
                        <div className="d-inline-block m-2 pr-10 mt-5rem">
                          <a href="https://play.google.com/store/apps/details?id=com.happyhealthylife.with&hl=en"
                             target="_blank" rel="noreferrer">
                            <img src={play_store_image} className="img-ht shadow br-1" alt="Play Store" />
                          </a>
                        </div>
                        <div className="d-inline-block m-2 pl-10 mt-5rem">
                          <a href="https://apps.apple.com/in/app/jeewith/id1560598766" target="_blank" rel="noreferrer">
                            <img src={app_store_image} className="img-ht shadow br-1" alt="App Store" />
                          </a>
                        </div>
                        <div className="pt-7rem">
                            <div>
                              <iframe
                                width="750"
                                height="500"
                                src="https://www.youtube.com/embed/TTlbPs8g5fY"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>

                            </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-12"></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Introduction to Jeewith */}
              <div className="pt-9rem pb-9rem bg-grey-2">
                <div className="text-align-left m-3">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-7 col-12">
                        <p className="fs-30 text-white fw-500 ts-black-5 lh-30 mr-5">
                          Habit Building Made Easy and Rewarding
                          <img className="mb-3 mt-3" src={pro_logo_image} style={{ width: '40px' }} alt="Pro Logo" />
                        </p>
                        <div className="m-0 fs-11 fw-400 ff-gilroy-medium font-light-black-a">
                          <p>Habit building with a proper workout routine brings good health, and success in life.</p>
                          <p>In Jeewith, you can subscribe to wellness activities and build your habits by tracking your
                            routines. You can always build the routine of your choice; set your own goals and
                            challenges. Get connected to fellow habit builders by joining the challenges.</p>
                          <p>Enjoy the joy of competing with your habit-building pals and excel in wellness. Choose from
                            a wide variety of rewards in our app to reward your efforts.</p>
                        </div>
                      </div>
                      <div className="col-md-1 col-12"></div>
                      <div className="col-md-4 col-12">
                        <div className="w-100">
                          <img src={ss_1_image} className="img-fluid w-100" alt="Screenshot 1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Features */}
              <div className="pt-7rem pb-7rem bg-grey-1">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="bg-grey-2 card shadow-lg br-4 overflow-hidden"
                           style={{ boxShadow: '0px 0px 6px 0px rgb(0 0 0) !important' }}>
                        <div className="p-5">
                          <p className="fs-18 text-white fw-500 ts-black-3">Keep a track of your overall wellness.</p>
                          <p className="fs-10 ff-gilroy-medium font-light-black-a">Experience this journey of wellness by
                            taking small steps every day and rewarding yourself for every activity you do.</p>
                        </div>
                        <div className="pr-5 pl-5">
                          <img src={image_2a} className="img-fluid w-100" alt="Feature 1" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="bg-grey-2 card shadow-lg br-4 overflow-hidden"
                           style={{ boxShadow: '0px 0px 6px 0px rgb(0 0 0) !important' }}>
                        <div className="p-5">
                          <p className="fs-18 text-white fw-500 ts-black-3">Get rewarded for healthy lifestyle.</p>
                          <p className="fs-10 ff-gilroy-medium font-light-black-a">Every effort goes with recognition.
                            Update your wellness activities and earn exciting rewards!</p>
                        </div>
                        <div className="pr-5 pl-5">
                          <img src={features_2_image} className="img-fluid w-100" alt="Feature 2" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Premium */}
              <div id="Get_Premium" className="pt-55 pb-7rem bg-grey-2">
                <div className="container">
                  <div>
                    <p className="fs-22 text-white fw-600 ts-black-3 text-center">
                      Premium goes live on <span className="font-warning">30th April</span> ! Mark your calendars
                    </p>
                  </div>
                  <div className="row pt-5 pb-5">
                    <div className="col-md-7 col-12">
                      <div>
                        <p className="fs-18 ff-gilroy-medium fw-400 text-white">
                          Subscribe to premium and get access to exclusive wellness content
                        </p>
                      </div>
                      <div className="pt-4 pb-4 fs-13 ff-gilroy-medium">
                        <ul className="list-unstyled">
                          <li className="text-white mb-3">
                          <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                            Access to Exclusive Workouts, Meditations
                          </li>
                          <li className="text-white mb-3">
                          <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                            100+ Healthy Recipes Cookbook
                          </li>
                          <li className="text-white mb-3">
                          <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                            50% Higher chances of Redeeming Wellness Rewards
                          </li>
                          <li className="text-white mb-3">
                          <i class="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                            Access to Diet Planner, Workout Builder, Wearable App
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-5 col-12 pr-0 pl-0 pt-3">
                      <div className="pr-3 pl-3">
                        <img src={features_2_image} className="img-fluid w-100" alt="Videos" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Testimonials and Track Record */}
              <div className="pt-9rem pb-9rem bg-grey-1">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <div>
              <p className="fs-22 font-white fw-500 ts-black-3 text-center">User Testimonials</p>
            </div>
            <div>
              <Slider {...settings}>
                <div>
                  <div className="container-fluid pt-5 pb-5">
                    <div className="row">
                      <div className="col-md-6 p-0 col-12">
                        <div className="p-2 m-3">
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-md-7 col-12">
                                <p className="fs-11 mb-0 font-light-black-c ff-gilroy-medium">
                                  The one major change I found in myself was that I was looking forward to reaching the goals that I set for myself.
                                </p>
                                <h3 className="fs-15 mt-4 font-white">- Vaishnavi</h3>
                              </div>
                              <div className="col-md-5 col-12">
                                <div className="br-2 overflow-hidden">
                                <img src={vaishnavi_image} className="img-fluid w-100 mb-3 br-1" alt="Vamshi" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 p-0 col-12">
                        <div className="p-2 m-3">
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-md-7 col-12">
                                <p className="fs-11 mb-0 font-light-black-c ff-gilroy-medium">
                                  I’ve started waking up early in the mornings, it helps me stay hydrated by reminding me to drink water regularly, this app helped me become a morning person! I love this app and will continue using it.
                                </p>
                                <h3 className="fs-15 mt-4 font-white">- Sai Pavan</h3>
                              </div>
                              <div className="col-md-5 col-12">
                                <div className="br-2 overflow-hidden">
                                <img src={ram_image} className="img-fluid w-100 mb-3 br-1" alt="Vamshi" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="container-fluid pt-5 pb-5">
                    <div className="row">
                      <div className="col-md-6 p-0 col-12">
                        <div className="p-2 m-3">
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-md-7 col-12">
                                <p className="fs-11 mb-0 font-light-black-c ff-gilroy-medium">
                                  I’ve seen some considerable changes in my everyday lifestyle since I started using the app. I developed some new healthy habits like waking up early in the morning to maintain a fitness routine.
                                </p>
                                <h3 className="fs-15 mt-4 font-white">- Aishwarya</h3>
                              </div>
                              <div className="col-md-5 col-12">
                                <div className="br-2 overflow-hidden">
                                <img src={aishwariya_image} className="img-fluid w-100 mb-3 br-1" alt="Vamshi" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 p-0 col-12">
                        <div className="p-2 m-3">
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-md-7 col-12">
                                <p className="fs-11 mb-0 font-light-black-c ff-gilroy-medium">
                                  The app is very positive, reminding me to keep my body hydrated and also helps me keep up with my physical fitness. I love tracking my wellness activities. This is promoting me to be productive and more energetic.
                                </p>
                                <h3 className="fs-15 mt-4 font-white">- Vamshi Krishna Naik</h3>
                              </div>
                              <div className="col-md-5 col-12">
                                <div className="br-2 overflow-hidden">
                                <img src={vamshi_image} className="img-fluid w-100 mb-3 br-1" alt="Vamshi" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>

             {/* Vendors */}
             <div className="pt-55 pb-7rem bg-grey-2">
      <div className="row">
        <div className="col-md-1 col-12"></div>
        <div className="col-md-10 col-12 text-center">
          <h2 className="mr-auto ml-auto mb-5 mt-0 decorated">
            <span className="fs-22 text-white fw-500 ts-black-3 text-center">
              Brands We Have Partnered With
            </span>
          </h2>
        </div>
      </div>
      <div className="container p-5">
        <div className="row">
          {vendors.map((vendor, index) => (
            <div className="col-md-2 mb-3 col-12" key={index}>
                <div>
                  <img
                    src={vendor.image}
                    alt={vendor.name}
                    className="img-lg object-fit-cover br-3 overflow-hidden"
                  />
                </div>
            </div>
          ))}
        </div>
      </div>
    </div>
              {/* Footer */}
              <FootNav />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}


export default BasicWithPage