import React from 'react'
import './FootNav.css'
import '../../pages/basic-with-page/BasicWithPage.css'
import play_store_image from '../../assets/images/play_store.png';
import app_store_image from '../../assets/images/app-store.png';
import logo_img from '../../assets/images/jeewithlogo.jpg';

const FootNav = () => {
    return (
        <div>
            {/* Mobile footer */}
            <footer className="site-footer d-lg-none">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-align-center">
                            <img src={logo_img} className="img-logo-foot" alt="Jeewith Logo" />
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.happyhealthylife.with&hl=en"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={play_store_image} className="shadow br-1 img-size ml-4" alt="Play Store" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <a
                                        href="https://apps.apple.com/in/app/jeewith/id1560598766"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={app_store_image} className="shadow br-1 img-size" alt="App Store" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <ul className="list-unstyled">
                                <li className="mb-2x5">
                                    <a className="font-white fs-12" href="#!">Quick Links</a>
                                </li>
                                <li className="mb-2x5">
                                    <a href="/static/privacypolicy" target="_blank" rel="noopener noreferrer">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="/static/termsandconditions" target="_blank" rel="noopener noreferrer">
                                        Terms & Conditions
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul className="list-unstyled">
                                <li className="mb-2x5">
                                    Contact - 918977009484
                                </li>
                                <li>
                                    <a href="mailto:admin@hhlife.in?Subject=Contact%20Jeewith" target="_top">
                                        Email: admin@hhlife.in
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-6">
                            <span className="fs-18 fw-600 font-white ml-2">Follow us on</span>
                        </div>
                        <div className="col-6">
                            <ul className="social-icons">
                                <li>
                                    <a className="facebook" href="https://www.facebook.com/jeewith" target="_blank" rel="noopener noreferrer">
                                        <i class="bi bi-facebook"></i>                </a>
                                </li>
                                <li>
                                    <a className="instagram" href="https://www.instagram.com/jeewith_app" target="_blank" rel="noopener noreferrer">
                                        <i class="bi bi-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a className="linkedin" href="https://www.linkedin.com/company/71863212" target="_blank" rel="noopener noreferrer">
                                        <i class="bi bi-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a className="twitter" href="https://www.twitter.com/jeewith_app" target="_blank" rel="noopener noreferrer">
                                        <i class="bi bi-twitter-x"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="m-0 text-center ff-gilroy-medium fs-8x5 font-primary-grey-8">
                                Copyright &copy; 2022 - All Rights Reserved by
                            </p>
                            <p className="text-center">
                                <a href="https://hhlife.in" className="font-warning ff-gilroy-medium" target="_blank" rel="noopener noreferrer">
                                    Happy Healthy Life Private Limited
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Desktop footer */}
            <footer className="site-footer pt-3 d-none d-lg-block">
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-6">
            <img src={logo_img} className="logo-sz pt-2 pr-3 pl-3 bg-grey-2" alt="Logo" />
            <div className="row pt-5">
             <div className='col-md-4'>
             <div className="d-inline-block pr-4">
                <a href="https://play.google.com/store/apps/details?id=com.happyhealthylife.with&hl=en" target="_blank" rel="noopener noreferrer">
                  <img src={play_store_image} className="shadow br-1 img-store ml-4" alt="Google Play Store" />
                </a>
              </div>
             </div>
              <div className='col-md-4'>
              <div className="d-inline-block pl-4">
                <a href="https://apps.apple.com/in/app/jeewith/id1560598766" target="_blank" rel="noopener noreferrer">
                  <img src={app_store_image} className="shadow br-1 img-store" alt="Apple App Store" />
                </a>
              </div>
              </div>
            </div>
            <div className="row pt-35">
              <div className="col-md-12">
                <span className="fs-18 fw-600 font-white ml-2">Follow us on</span>
              </div>
              <div className="row pt-2">
                <div className="col-md-12 pr-4 ml-2">
                  <ul className="social-icons list-unstyled d-flex">
                    <li className="mr-3">
                      <a href="https://www.facebook.com/jeewith" target="_blank" rel="noopener noreferrer" className="facebook">
                      <i class="bi bi-facebook"></i> 
                      </a>
                    </li>
                    <li className="mr-3">
                      <a href="https://www.instagram.com/jeewith_app" target="_blank" rel="noopener noreferrer" className="instagram">
                      <i class="bi bi-instagram"></i> 
                      </a>
                    </li>
                    <li className="mr-3">
                      <a href="https://www.linkedin.com/company/71863212" target="_blank" rel="noopener noreferrer" className="linkedin">
                      <i class="bi bi-linkedin"></i> 
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/jeewith_app" target="_blank" rel="noopener noreferrer" className="twitter">
                      <i class="bi bi-twitter-x"></i> 
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-2'>

          </div>
          <div className="col-md-4 text-right">
            <ul className="list-unstyled">
              <li className="mb-4 mt-3">
                <span className="font-white fs-15">Quick Links</span>
              </li>
              <li className="mb-2">
                <a className="ff-gilroy-medium" href="/static/privacypolicy">Privacy Policy</a>
              </li>
              <li className="mb-2">
                <a className="ff-gilroy-medium" href="/static/termsandconditions">Terms & Conditions</a>
              </li>
              <br />
              <li className="mb-4 mt-3">
                <span className="font-white fs-15">Contact Us</span>
              </li>
              <li className="mb-2">
                <p className="ff-gilroy-medium m-0">+91-8977009484, admin@hhlife.in</p>
              </li>
              <li className="mb-2">
                <p className="ff-gilroy-medium m-0">Happy Healthy Life, Hyderabad, India</p>
              </li>
            </ul>
          </div>
          <div className="text-center">
            <p className="copyright-text ff-gilroy-medium fs-8x5 mt-4 mb-4 font-primary-grey-8">
              Copyright &copy; 2024 - All Rights Reserved by{' '}
              <a href="https://hhlife.in" className="font-warning ff-gilroy-medium" target="_blank" rel="noopener noreferrer">
                Happy Healthy Life Private Limited
              </a>.
            </p>
          </div>
        </div>
      </div>
    </footer>
        </div>
    )
}

export default FootNav