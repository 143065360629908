import { Fragment } from 'react';
import './App.css';
import BasicWithPage from './pages/basic-with-page/BasicWithPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import BasicJeewithAboutUs from './pages/basic-jeewith-about-us/BasicJeewithAboutUs';
import BasicWellnessEmployersPage from './pages/basic-wellness-employers-page/BasicWellnessEmployersPage';
import BasicFootNavPrivacyPolicyPage from './pages/basic-foot-nav-privacy-policy-page/BasicFootNavPrivacyPolicyPage';
import BasicFootNavTermsAndConditionsPage from './pages/basic-foot-nav-terms-and-conditions-page/BasicFootNavTermsAndConditionsPage';

function App() {
  return (
    <>
    <Fragment>
      <Routes>
      <Route path="/" element={<Navigate to="/basic/jeewith" />} />
        <Route path="/basic/jeewith" element={<BasicWithPage />} />
        <Route path="/basic/jeewith/aboutus" element={<BasicJeewithAboutUs />} />
        <Route path="/basic/jeewith/employers" element={<BasicWellnessEmployersPage />} />
        <Route path="/static/privacypolicy" element={<BasicFootNavPrivacyPolicyPage />} />
        <Route path="/static/termsandconditions" element={<BasicFootNavTermsAndConditionsPage />} />
      </Routes>
      </Fragment>
    </>
  );
}

export default App;
