import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/free-solid-svg-icons';
import './top-nav.css';
import  logo_img  from '../../assets/images/jeewithlogo.jpg';

function Navbar() {
  const [collapse, setCollapse] = useState(true);

  return (
    <>
      {/* MOBILE NAVBAR */}
      <div className="d-lg-none">
        <nav className="navbar sticky-top navbar-expand-lg float-right w-100p" style={{ background: '#222222' }}>
          <div className="row">
            <div className="col-md-12 col-12 menu">
              <ul className="nav nav-tabs w-fit-content mr-auto">
                <li role="presentation">
                  <Link className="navbar-brand" to="/basic/jeewith">
                    <img src={logo_img} className="img-logo" alt="Jeewith Logo" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <span
            className="navbar-toggler nav-col"
            onClick={() => setCollapse(!collapse)}
            aria-expanded={!collapse}
            aria-label="Toggle navigation"
          >
            {/* <FontAwesomeIcon icon={faBars} className="font-white fs-15 fw-500" /> */}
            <i className="bi bi-list fs-15 fw-500 text-white"></i>
          </span>
          <div className={`navbar-collapse menu ${collapse ? 'd-none' : ''}`} onClick={() => setCollapse(true)}>
            <ul className="navbar-nav ml-auto">
              <button role="presentation" className="btn-mb">
                <a href="https://play.google.com/store/apps/details?id=com.happyhealthylife.with&hl=en" target="_blank" rel="noopener noreferrer" className="fs-11 fw-600 font-white">
                  Download Now
                </a>
              </button>
              &nbsp;
            </ul>
          </div>
        </nav>
      </div>

      {/* DESKTOP NAVBAR */}
      <nav className="navbar p-0 d-none d-lg-block pt-3" style={{ background: '#000000d9', borderBottom: '2px solid #222' }}>
        <div className="container">
          <div className="row w-100">
            <div className="col-md-4">
              <ul className="nav nav-tabs w-fit-content mr-auto">
                <li role="presentation">
                  <Link
                    className="navbar-brand bg-grey-2 br-0 pt-2x5 pb-2x5 pr-3 pl-3 position-fixed"
                    to="/basic/jeewith"
                    style={{ position: 'fixed', top: 0, border: '2px solid #000', borderTop: 'none' }}
                  >
                    <img src={logo_img} className="img-sz" alt="Jeewith Logo" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-8">
              <ul className="nav w-fit-content ml-auto">
                <li className="nav-item pr-4" role="presentation">
                  <Link className="nav-link font-white fs-10 fw-500 h-4 pt-3" to="/basic/jeewith/aboutus">
                    About Us
                  </Link>
                </li>
                <li className="nav-item pr-4" role="presentation">
                  <Link className="nav-link font-white fs-10 fw-500 h-4 pt-3" to="/basic/jeewith/employers">
                    Employers
                  </Link>
                </li>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                <li className="nav-item pr-3 pl-3" role="presentation">
                  <button className="btn-yellow h-3 br-1">
                    <a href="https://play.google.com/store/apps/details?id=com.happyhealthylife.with&hl=en" target="_blank" rel="noopener noreferrer" className="fs-10 fw-500 text-black text-decoration-none">
                      Download Now
                    </a>
                  </button>
                </li>
                {/* <li role="presentation">
                  <Link to="/basic/home" className="btn btn-black border-grey-c font-white fs-10 fw-500 h-3 br-1">
                    Visit Jeewith Pro
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
