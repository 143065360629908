import React from 'react';
import '../basic-with-page/BasicWithPage.css'
import Navbar from '../../components/top-nav/top-nav';
import FootNav from '../../components/foot-nav/FootNav';

const BasicJeewithAboutUs = () => {
  return (
    <div>
        <Navbar />
        <div>
        <div className="pt-35 pb-2x5 bg-grey-2">
      <div className="container">
        <p className="fs-22 font-white fw-600 ts-black-3 text-center pt-35">
          Mission &nbsp;
          <i className="bi bi-flag fs-18"></i>
                  </p>
        <div className="row">
          <div className="col-md-12">
            <div className="pt-4 pb-4 fs-13 font-white ff-gilroy-medium">
              <ul className="list-unstyled">
                <li>
                      <i className="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                  Wellness is a state of overall well-being. In simple terms, achieving Strong Body, Calm Mind
                  and Pure Soul and maintaining it. More importantly, Wellness is a continuous journey. We
                  made it our mission to enable that journey through platforms.
                </li>
                <br />
                <li>
                      <i className="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                  With our Jeewith platform, we are trying to be a one-stop shop that acts as a platform for
                  wellness journeys for people. It starts as a habit-building platform, helping people build
                  the right connections, get the right coaching and guidance, and inspire others to join the
                  movement of wellness by rewarding them.
                </li>
                <br />
                <li>
                      <i className="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                  Jeewith Pro is a platform for lifestyle coaching addressing lifestyle diseases.
                  Professionals like nutritionists, yoga trainers, fitness coaches, physiotherapists, and
                  naturopathy experts can use the platform. They can work on preventing or reversing lifestyle
                  diseases using this platform.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
        <FootNav />
    </div>
  )
}

export default BasicJeewithAboutUs