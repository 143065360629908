import React from 'react'
import Navbar from '../../components/top-nav/top-nav'
import FootNav from '../../components/foot-nav/FootNav'
import trns1 from '../../assets/images/trns-1.png'
import trns2 from '../../assets/images/trns-2.png'
import freyr from '../../assets/images/freyr.png'
import eidiko from '../../assets/images/eidiko.png'
import perigord from '../../assets/images/perigord.png'
import whizzy from '../../assets/images/whizzy.png'

const BasicWellnessEmployersPage = () => {
  return (
    <div>
        <Navbar />
        <div className="pt-9rem pb-7rem bg-grey-2">
      <div className="container">
        <div>
          <h2 className="fs-22 font-white fw-600 ts-black-3 text-center">
            Transform your Workforce
          </h2>
        </div>
        <div className="row pt-5 pb-5">
          <div className="col-md-6">
            <div className="pt-4 pb-4 fs-13 ff-gilroy-medium">
              <ul className="list-unstyled">
                <li className="font-white mb-3">
                      <i className="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                  Premium for all Employees
                </li>
                <li className="font-white mb-3">
                      <i className="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                  Customize your own Products for the Store
                </li>
                <li className="font-white mb-3">
                      <i className="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                  Engage and Empower your Employees
                </li>
                <li className="font-white mb-3">
                      <i className="bi bi-check-circle pl-2 pr-2 font-success fs-10" style={{color: "green"}}></i> &nbsp;
                  Compete with co-workers in Fun Challenges
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <img
                  src={trns1}
                  alt="Transform Image 1"
                  className="w-100"
                  style={{
                    borderRadius: '1rem',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <div className="col-md-6">
                <img
                  src={trns2}
                  alt="Transform Image 2"
                  className="w-100"
                  style={{
                    borderRadius: '1rem',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-4 mb-4">
          <div className="col-md-4">
            <h2 className="mb-0 fs-20 fw-600 font-white">
              Employers Making the Change Happen
            </h2>
          </div>
          <div className="col-md-8 p-0">
            <div className="d-inline-block"
              style={{
                width: 'calc(25% - 2rem)',
                margin: '0 1rem',
                borderRadius: '2rem',
                border: 'none',
                background: '#fff',
                boxShadow: '0 0 20px 0px #0000002b',
              }}>
              <img
                src={freyr}
                alt="Freyr"
                style={{
                  background: '#fff',
                  borderRadius: '1rem',
                  objectFit: 'cover',
                  width: '100%',
                  padding: '1.5rem 0.9rem',
                  margin: 'auto',
                  objectPosition: 'center',
                }}
              />
            </div>
            <div className="d-inline-block"
              style={{
                width: 'calc(25% - 2rem)',
                margin: '0 1rem',
                borderRadius: '2rem',
                border: 'none',
                background: '#fff',
                boxShadow: '0 0 20px 0px #0000002b',
              }}>
              <img
                src={eidiko}
                alt="Eidiko"
                style={{
                  background: '#fff',
                  borderRadius: '1rem',
                  objectFit: 'cover',
                  width: '100%',
                  padding: '1.5rem 0.9rem',
                  margin: 'auto',
                  objectPosition: 'center',
                }}
              />
            </div>
            <div className="d-inline-block"
              style={{
                width: 'calc(25% - 2rem)',
                margin: '0 1rem',
                borderRadius: '2rem',
                border: 'none',
                background: '#fff',
                boxShadow: '0 0 20px 0px #0000002b',
              }}>
              <img
                src={perigord}
                alt="Perigord"
                style={{
                  background: '#fff',
                  borderRadius: '1rem',
                  objectFit: 'cover',
                  width: '100%',
                  padding: '1.5rem 0.9rem',
                  margin: 'auto',
                  objectPosition: 'center',
                }}
              />
            </div>
            <div className="d-inline-block"
              style={{
                width: 'calc(25% - 2rem)',
                margin: '0 1rem',
                borderRadius: '2rem',
                border: 'none',
                background: '#fff',
                boxShadow: '0 0 20px 0px #0000002b',
              }}>
              <img
                src={whizzy}
                alt="Whizzy"
                style={{
                  background: '#fff',
                  borderRadius: '1rem',
                  objectFit: 'cover',
                  width: '100%',
                  padding: '1.5rem 0.9rem',
                  margin: 'auto',
                  objectPosition: 'center',
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h2 className="mb-0 fs-20 fw-600 font-white details-btn">
              Excited to try at your workplace? <span className="font-white fs-18">Please contact us on <a
                href="mailto:business@hhlife.in"
                target="_blank"
                className="font-blue">business@hhlife.in</a> to know more</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
        <FootNav />
    </div>
  )
}

export default BasicWellnessEmployersPage