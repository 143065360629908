import React from 'react'
import Navbar from '../../components/top-nav/top-nav'
import FootNav from '../../components/foot-nav/FootNav'

const BasicFootNavPrivacyPolicyPage = () => {
    return (
        <div style={{ background: "#222222" }}>
            <Navbar />
            <div className='pt-4rem'>
                <h1 className="text-center font-white">
                    <strong>Privacy Policy</strong>
                </h1>
                <br />
                <div className='row'>
                    <div className='col-md-2 col-1'>
                    </div>
                    <div className='col-md-8 col-10'>
                        <div className='container-fluid privacy-pol-div'>
                            <p className="font-white">
                                <strong>Last Updated on July 30, 2021</strong>
                            </p>
                            <br />
                            <p className="text-justify font-white">
                                We at Happy Healthy Life Private Limited ("<strong>Jeewith</strong>", "<strong>We</strong>", "<strong>Us</strong>", "<strong>Our</strong>") know that you as a user ("<strong>You</strong>", "<strong>Your</strong>", "<strong>User(s)</strong>") care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our privacy policy.
                            </p>
                            <br />
                            <p className="text-justify font-white">
                                By visiting or using our website (
                                <a href="https://jeewith.com/" target="_blank" rel="noopener noreferrer" className="font-blue">
                                    www.jeewith.com
                                </a>
                                ) and domain name, and any other linked pages, features, content, mobile applications, or any other services we offer from time to time by in connection therewith (collectively, the "<strong>Mobile App</strong>"), or by using the Services / Products (as defined in our Terms of Use) in any manner, you acknowledge that you accept the practices and policies outlined in this privacy policy, and you hereby consent that we will collect, use, and share your information in the following ways.
                            </p>
                            <br />
                            <p className="text-justify font-white">
                                <strong>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</strong>
                            </p>
                            <br />
                            <p className="text-justify font-white">
                                <strong>
                                    IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE WEBSITE/MOBILE APPLICATION.
                                </strong>
                            </p>
                            <br />
                            <br />
                            <div>
                                <h3 className="text-justify font-white">
                                    <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
                                </h3>
                                <br />
                                <h5 className="text-left font-white">
                                    <strong>Information you provide</strong>
                                </h5>
                                <p className="text-justify font-white">
                                    <i>
                                        We collect certain personal information necessary to provide you services, when you create an account with us.
                                    </i>
                                </p>
                                <ul className="text-justify font-white">
                                    <li>
                                        We collect personal information such as your name and email address when you create an account with us.
                                    </li>
                                    <li>
                                        We require certain personal information to provide you services such as
                                        - your birth date, gender, phone number, mailing address;
                                        - your weight, height, lifestyle, food preferences, nutrition data, workouts, physical activity, photographs, medical conditions, health goals, steps, sleep habits or other fitness regimes and health-related information as our services and products are further developed;
                                    </li>
                                    <li>
                                        <strong>Your Contributions.</strong> Information and content, you provide. We collect the content, communications and other information you provide when you use our Products, including when you sign up for an account, create or share content, and message or communicate with others. This can include any information in or about the content (including without limitation, any text, information, messages, photos, images, the date a file was created), data, questions, comments, suggestions or other content, including personally identifiable information that you upload, send, email, display, perform, distribute, post or otherwise transmit to us, at our request or on your own, or through the Services (such as discussion boards, reviews), whether in connection with your use of the Services or through the use of any Third Party Websites or Third Party Services or otherwise, and whether publicly posted or privately transmitted.
                                    </li>
                                    <li>
                                        <strong>Your Messages.</strong> We store messages, photos, videos, and documents from your chats on our servers so that you can access your chat data from any of your devices anytime without having to rely on third-party backups. All data is stored encrypted. Our developers do not access user data.
                                    </li>
                                    <li>
                                        <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Instamojo.
                                    </li>
                                </ul>
                                <br />
                                <h5 className="text-left font-white">
                                    <strong>Information automatically collected</strong>
                                </h5>
                                <p className="text-justify font-white">
                                    <i>
                                        Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our Services or Apps.
                                    </i>
                                </p>
                                <br />
                                <p className="text-justify font-white">
                                    We automatically collect certain information when you visit, use or navigate the Services through our Apps or Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, referring URLs, device name, country, location, information about how and when you use our Services or Apps and other technical information. This information is primarily needed to maintain the security and operation of our Services or Apps, and for our internal analytics and reporting purposes.
                                </p>
                                <br />
                                <p className="text-justify font-white">
                                    Like many businesses, we also collect information through cookies and similar technologies.
                                </p>
                                <h5 className="text-left font-white">
                                    <strong>Information collected through our Apps</strong>
                                </h5>
                                <p className="text-justify font-white">
                                    <i>
                                        We may collect information regarding your mobile device, push notifications, when you use our apps.
                                    </i>
                                </p>
                                <br />
                                <p className="text-justify font-white">
                                    If you use our Apps, we may also collect the following information:
                                </p>
                                <ul className="text-justify font-white">
                                    <li>
                                        <strong>Mobile Device Access.</strong> We may request access or permission to certain features from your mobile device, including your
                                        <ul>
                                            <li>
                                                Mobile device’s storage to read & write videos, pictures & pdf files from the device,
                                            </li>
                                            <li>
                                                Contacts to find your connections and share data with them
                                            </li>
                                            <li>
                                                Camera to capture images & videos to provide services
                                            </li>
                                            <li>
                                                Social media accounts to login to your{" "}
                                                <a href="https://jeewith.com/" target="_blank" rel="noopener noreferrer" className="font-blue">
                                                    Jeewith
                                                </a>{" "}
                                                account.
                                            </li>
                                            <li>
                                                Data about health from iOS HealthKit, Health app, and Google Fit.
                                                <br />
                                                <p>
                                                    We handle all iOS Health app and HealthKit data in accordance with Apple's development guidelines. To view Apple's HealthKit developer guidelines, go to{" "}
                                                    <a href="https://developer.apple.com/app-store/review/guidelines/#health-and-health-research" target="_blank" rel="noopener noreferrer" className="font-blue">
                                                        https://developer.apple.com/app-store/review/guidelines/#health-and-health-research
                                                    </a>
                                                    .
                                                </p>
                                                <p>
                                                    We handle Google Fit data in accordance with Google Fit Terms of Service. To view Google Fit Terms of Service go to{" "}
                                                    <a href="https://developers.google.com/fit/terms" target="_blank" rel="noopener noreferrer" className="font-blue">
                                                        https://developers.google.com/fit/terms
                                                    </a>
                                                    .
                                                </p>
                                            </li>
                                        </ul>
                                        <i>
                                            If you wish to change any of our access or permissions, you may do so in your device’s settings. However, revoking these permissions may prevent you from using all or some of the functionality of our services.
                                        </i>
                                    </li>
                                    <li>
                                        <strong>Mobile Device Data.</strong> We may automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and IP address.
                                    </li>
                                    <li>
                                        <strong>Push Notifications.</strong> We may request to send you push notifications regarding your account or the mobile application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-justify font-white">
                                    <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
                                </h3>
                                <br />
                                <p className="text-justify font-white">
                                    <i>
                                        We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
                                    </i>
                                </p>
                                <br />
                                <p className="text-justify font-white">
                                    We use personal information collected via our Services or Apps for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
                                </p>
                                <p className="text-justify font-white">
                                    We use the information we collect or receive:
                                </p>
                                <br />
                                <ul className="text-justify font-white">
                                    <li>
                                        To facilitate account creation and logon process. If you choose to link your account with us to a third-party account *(such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.
                                    </li>
                                    <p className="text-justify font-white">
                                        Our Services or Apps offer you the ability to register and login using your third-party social media account details (like your Facebook or Google logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, e-mail address, profile picture as well as other information you choose to make public.
                                    </p>
                                    <li>
                                        We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the Services or Apps. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy policy to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.
                                    </li>
                                    <li>
                                        To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
                                    </li>
                                    <li>
                                        To track your weight and fitness routine. We require information about your eating habits, changes in your body weight and your fitness regime to craft a personalised Nutrition and Training Plans and to monitor your progress.
                                    </li>
                                    <li>
                                        Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services or Apps.
                                    </li>
                                    <li>
                                        To post testimonials. We post testimonials on our Services or Apps that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your testimonial, please contact us at business@hhlife.in and be sure to include your name, testimonial location, and contact information.
                                    </li>
                                    <li>
                                        Request Feedback. We may use your information to request feedback and to contact you about your use of our Services or Apps.
                                    </li>
                                    <li>
                                        To protect our Services. We may use your information as part of our efforts to keep our Services or Apps safe and secure (for example, for fraud monitoring and prevention).
                                    </li>
                                    <li>
                                        To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent.
                                    </li>
                                    <li>
                                        To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and Contractual.
                                    </li>
                                    <li>
                                        To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
                                    </li>
                                    <li>
                                        To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.
                                    </li>
                                    <li>
                                        To deliver services to the user. We may use your information to provide you with the requested service.
                                    </li>
                                    <li>
                                        To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.
                                    </li>
                                    <li>
                                        For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services or Apps, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-justify font-white">
                                    <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
                                </h3>
                                <br />
                                <p className="text-justify font-white">
                                    <i>
                                        We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
                                    </i>
                                </p>
                                <br />
                                <p className="text-justify font-white">
                                    We may process or share data based on the following legal basis:
                                </p>
                                <br />
                                <ul className="text-justify font-white">
                                    <li>
                                        Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
                                    </li>
                                    <p className="text-justify font-white">
                                        Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
                                    </p>
                                    <li>
                                        Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
                                    </li>
                                    <li>
                                        Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                                    </li>
                                    <li>
                                        Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
                                    </li>
                                    <p className="text-justify font-white">
                                        Situations where we may need to process your data or share your personal information are as follows:
                                    </p>
                                    <li>
                                        Other Users. When you share personal information (for example, by posting comments, contributions or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of the Website/Mobile Application, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, posting comments, posting reviews, following Forums.
                                    </li>
                                    <li>
                                        Online Postings. When you post comments, contributions or other content to the Website/Mobile Application, your posts may be viewed by all users and may be publicly distributed outside the Website/Mobile Application in perpetuity.
                                    </li>
                                    <li>
                                        Business Transfers. As user information is considered to be a vital company asset, we may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                                    </li>
                                    <li>
                                        Third-party Websites. The Website/Mobile Application may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Website/Mobile Application, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Website/Mobile Application.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-justify font-white">
                                    <strong>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</strong>
                                </h3>
                                <br />
                                <p className="text-justify font-white">
                                    <i>
                                        We only share information with the following third parties.
                                    </i>
                                </p>
                                <br />
                                <p className="text-justify font-white">
                                    We only share and disclose your information with the following third parties. We have categorized each party so that you may easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us by writing to business@hhlife.in.
                                </p>
                                <br />
                                <ul class="text-justify font-white">
                                    <li>
                                        <strong>Cloud Computing Services</strong>
                                        <ul>
                                            <li>
                                                Amazon Web Services (AWS)
                                                (<a href="https://aws.amazon.com/privacy/" target="_blank" class="font-blue">Privacy Policy</a>)
                                                (<a href="https://aws.amazon.com/compliance/gdpr-center/" target="_blank" class="font-blue">GDPR
                                                    Compliance</a>)
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Invoice and Billing</strong>
                                        <ul>
                                            <li>
                                                Instamojo
                                                (<a href="https://www.instamojo.com/company/privacy/" target="_blank" class="font-blue">Privacy
                                                    Policy</a>)
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>User Account Registration and Authentication</strong>
                                        <ul>
                                            <li>
                                                Facebook Login
                                            </li>
                                            <li>
                                                Google Sign-In
                                            </li>
                                            <li>
                                                AWS Cognito
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Web and Mobile Analytics</strong>
                                        <ul>
                                            <li>
                                                Google Analytics
                                                (<a href="https://www.google.com/intl/en-GB/policies/privacy/" target="_blank" class="font-blue">Privacy
                                                    Policy</a>)
                                                (<a href="https://privacy.google.com/businesses/compliance/#?modal_active=none"
                                                    target="_blank" class="font-blue">GDPR
                                                    Compliance</a>)
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>App Testing</strong>
                                        <ul>
                                            <li>
                                                TestFlight
                                            </li>
                                            <li>
                                                Google Play Console
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Planning Nutrition & Fitness Plan</strong>
                                        <ul>
                                            <li>
                                                Health Coach
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Manage App</strong>
                                        <ul>
                                            <li>
                                                Jeewith Admins
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    <i>
                                        We may use cookies and other tracking technologies to collect and store your information.
                                    </i>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    You can visit our website without giving away your personal information. We may use cookies, Google
                                    Analytics and other tracking technologies on the Website/Mobile Application to help customize the
                                    Website/Mobile Application and improve your experience and analyse how the Website/Mobile Application is
                                    used. The information collected by Google Analytics is anonymous Traffic Data on the website. We do not
                                    collect any additional information such as name, age, gender, etc upon your usage of the Website without
                                    the need to create an account.
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    The data subject may, at any time, prevent the setting of cookies through our website by means of a
                                    corresponding setting of the Internet browser used, and may thus permanently deny the setting of
                                    cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other
                                    software programs. This is possible in all popular Internet browsers. If the data subject deactivates
                                    the setting of cookies in the Internet browser used, not all functions of our website may be entirely
                                    usable.
                                </p>
                                <br />
                                <h5 class="text-left font-white">
                                    <strong>Website Analytics</strong>
                                </h5>
                                <p class="text-justify font-white">
                                    We may also partner with selected third-party vendors, such as Google Analytics, to allow tracking
                                    technologies and remarketing services on the Website through the use of first party cookies and
                                    third-party cookies, to, among other things, analyze and track users’ use of the Site, determine the
                                    popularity of certain content and better understand online activity. By accessing the Website, you
                                    consent to the collection and use of your information by these third-party vendors. You are encouraged
                                    to review their privacy policy and contact them directly for responses to your questions. We do not
                                    transfer personal information to these third-party vendors. However, If you do not want any information
                                    to be collected and used by tracking technologies, you can find The Google Analytics privacy policy
                                    <a href="https://policies.google.com/privacy" target="_blank" class="font-blue">here.</a> You can learn
                                    more about how to
                                    opt-out of tracking in Google Analytics
                                    <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank" class="font-blue">here</a>
                                    or
                                    <a href="https://developers.facebook.com/docs/facebook-login/permissions" target="_blank"
                                        class="font-blue">
                                        Network Advertising Initiative Opt-Out Tool.
                                    </a>
                                </p>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    <i>
                                        If you choose to register or log in to our services using a social media account, we may have access
                                        to certain information about you.
                                    </i>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    Our Services or Apps offer you the ability to register and login using your third-party social media
                                    account details (like your Facebook or Google logins). Where you choose to do this, we will receive
                                    certain profile information about you from your social media provider. The profile Information we
                                    receive may vary depending on the social media provider concerned, but will often include your name,
                                    e-mail address, friends list, profile picture as well as other information you choose to make public.
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    We will use the information we receive only for the purposes that are described in this privacy policy
                                    or that are otherwise made clear to you on the Services or Apps. Please note that we do not control, and
                                    are not responsible for, other uses of your personal information by your third-party social media
                                    provider. We recommend that you review their privacy policy to understand how they collect, use and
                                    share your personal information, and how you can set your privacy preferences on their sites and apps.
                                </p>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    <i>
                                        We may transfer, store, and process your information in countries other than your own.
                                    </i>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    All web servers and hosting are managed by our team using Amazon Web Services in the Asia South Region.
                                    This includes website hosting, backups, web database, file storage, APIs, and log files. Amazon’s
                                    privacy policy can be found here:
                                    <a href="https://aws.amazon.com/privacy/" target="_blank" class="font-blue">https://aws.amazon.com/privacy/.</a>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    If you are accessing our Services or Apps from outside Asia South Region, please be aware that your
                                    information may be transferred to, stored, and processed by us in our facilities and by those third
                                    parties with whom we may share your personal information, in India, and other countries.
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    If you are a resident in the European Economic Area, then these countries may not have data protection
                                    or other laws as comprehensive as those in your country. We will however take all necessary measures to
                                    protect your personal information in accordance with this privacy policy and applicable law.
                                </p>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>8. HOW LONG DO WE KEEP YOUR INFORMATION? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    <i>
                                        We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
                                        policy unless otherwise required by law.
                                    </i>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    We will only keep your personal information for as long as it is necessary for the purposes set out in
                                    this privacy policy, unless a longer retention period is required or permitted by law (such as tax,
                                    accounting or other legal requirements). No purpose in this policy will require us keeping your personal
                                    information for longer than the period of time in which users have an account with us.
                                </p>
                                <p class="text-justify font-white">
                                    When we have no ongoing legitimate business need to process your personal information, we will either
                                    delete or anonymize it, or, if this is not possible (for example, because your personal information has
                                    been stored in backup archives), then we will securely store your personal information and isolate it
                                    from any further processing until deletion is possible.
                                </p>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>9. HOW DO WE KEEP YOUR INFORMATION SAFE? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    <i>
                                        We aim to protect your personal information through a system of organizational and technical
                                        security measures.
                                    </i>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    We have implemented an appropriate Information Security Management System (ISMS) designed to protect the
                                    security of any personal information we process. This ISMS is established and managed in alignment with
                                    global best practices and certified towards ISO 27001:2013 standard. The system is subject to strong
                                    controls including ongoing monitoring, periodic security testing, internal/external audits and
                                    verifications. However, please also remember that we cannot guarantee that the internet itself is 100%
                                    secure. Although we will do our best to protect your personal information, transmission of personal
                                    information to and from our Services or Apps is at your own risk. You should only access the services
                                    within a secure environment.
                                </p>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>10. DO WE COLLECT INFORMATION FROM MINORS? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    <i>
                                        We do not knowingly collect data from or market to children under 18 years of age.
                                    </i>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    We do not knowingly solicit data from or market to children under 18 years of age. By using the Services
                                    or Apps, you represent that you are at least 18 or that you are the parent or guardian of such a minor
                                    and consent to such minor dependent’s use of the Services or Apps. If we learn that personal information
                                    from users less than 18 years of age has been collected, we will deactivate the account and take
                                    reasonable measures to promptly delete such data from our records. If you become aware of any data we
                                    have collected from children under age 18, please contact us at
                                    <a href="mailto:business@hhlife.in" target="_blank" class="font-blue">business@hhlife.in</a>
                                </p>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>11. WHAT ARE YOUR PRIVACY RIGHTS? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    <i>
                                        In some regions, such as the European Economic Area, you have rights that allow you greater access
                                        to and control over your personal information. You may review, change, or terminate your account at
                                        any time.
                                    </i>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    <a href="https://meity.gov.in/writereaddata/files/GSR313E_10511%281%29_0.pdf" target="_blank"
                                        class="font-blue">
                                        Information Technology (Reasonable security practices and procedures and sensitive personal data or
                                        information) Rules, 2011
                                    </a>
                                    permits our users who are Indian residents and
                                    <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32016R0679" target="_blank"
                                        class="font-blue"> The
                                        General Data Protection Regulation
                                    </a> (EU) permits our users who are EU residents the right to review or amend
                                    the Sensitive Personal Data or Information (SPDI) and give the information provider an option to retract
                                    consent at any point of time, in relation to the information that has been so provided.
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    In some regions (like the European Economic Area), you have certain rights under applicable data
                                    protection laws. These may include the right (i) to request access and obtain a copy of your personal
                                    information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
                                    information; and (iv) if applicable, to data portability. In certain circumstances, you may also have
                                    the right to object to the processing of your personal information. To make such a request, please
                                    contact us on <a href="mailto:business@hhlife.in" target="_blank"
                                        class="font-blue">business@hhlife.in</a>. We will
                                    consider and act upon any request in accordance with applicable data protection laws.
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    If we are relying on your consent to process your personal information, you have the right to withdraw
                                    your consent at any time. Please note however that this will not affect the lawfulness of the processing
                                    before its withdrawal.
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    If you are resident in the European Economic Area and you believe we are unlawfully processing your
                                    personal information, you also have the right to complain to your local data protection supervisory
                                    authority. You can find their contact details here:
                                    <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank"
                                        class="font-blue">
                                        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                    </a>
                                </p>
                                <ul class="font-white">
                                    <li>
                                        <strong>Account Information: </strong>
                                        If you would at any time like to review or change the information in your account or terminate your
                                        account, you can:
                                        <ul>
                                            <li>
                                                Log into your account settings and update your user account.
                                            </li>
                                            <li>
                                                Contact us using the contact information provided
                                            </li>
                                            <li>
                                                Upon your request to terminate your account, we will deactivate or delete your account and
                                                information from our active databases. However, some information may be retained in our
                                                files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our
                                                Terms of Use and/or comply with legal requirements.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Cookies and similar technologies: </strong>
                                        Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set
                                        your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject
                                        cookies, this could affect certain features or services of our Services or Apps.
                                    </li>
                                    <li>
                                        <strong>Opting out of email marketing: </strong>
                                        You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in
                                        the emails that we send or by contacting us using the details provided below. You will then be
                                        removed from the marketing email list – however, we will still need to send you service-related
                                        emails that are necessary for the administration and use of your account. To otherwise opt-out, you
                                        may contact us on <a href="mailto:business@hhlife.in" target="_blank" class="font-blue">business@hhlife.in</a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>12. CONTROLS FOR DO-NOT-TRACK FEATURES </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                                    (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about
                                    your online browsing activities monitored and collected. No uniform technology standard for recognizing
                                    and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser
                                    signals or any other mechanism that automatically communicates your choice not to be tracked online. If
                                    a standard for online tracking is adopted that we must follow in the future, we will inform you about
                                    that practice in a revised version of this privacy policy.
                                </p>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>13. DO WE MAKE UPDATES TO THIS POLICY? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    <i>
                                        Yes, we will update this policy as necessary to stay compliant with relevant laws.
                                    </i>
                                </p>
                                <br />
                                <p class="text-justify font-white">
                                    We may update this privacy policy from time to time. The updated version will be indicated by an updated
                                    “Revised” date and the updated version will be effective as soon as it is accessible. If we make
                                    material changes to this privacy policy, we may notify you either by prominently posting a policy of
                                    such changes or by directly sending you a notification. We encourage you to review this privacy policy
                                    frequently to be informed of how we are protecting your information.
                                </p>
                            </div>
                            <div>
                                <h3 class="text-justify font-white">
                                    <strong>14. HOW CAN YOU CONTACT US ABOUT THIS POLICY? </strong>
                                </h3>
                                <br />
                                <p class="text-justify font-white">
                                    If you have questions or comments about this policy, you may contact our Data Protection Officer (DPO),
                                    Prithvi Reddy, by email at <a href="mailto:prithvi@hhlife.in." target="_blank" class="font-blue">prithvi@hhlife.in</a>.
                                </p>
                            </div>
                            <div>
              <h3 class="text-justify font-white">
                <strong>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? </strong>
              </h3>
              <br/>
              <p class="text-justify font-white">
                Based on the applicable laws of your country, you may have the right to request access to the personal
                information we collect from you, change that information, or delete it in some circumstances. To request
                to review, update, or delete your personal information, please write to us at . We will respond to your
                request within 30 days.
              </p>
            </div>
                        </div>

                    </div>
                    <div className='col-md-1 col-1'>
                    </div>
                </div>
            </div>
            <FootNav />
        </div>
    )
}

export default BasicFootNavPrivacyPolicyPage